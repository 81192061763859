import { HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError((err) => {
      // TODO FVI rajouter la gestion d'erreurs
      console.log(err);
      if (err.status === 400) {
        console.log(err.error.error.details[0].messages[0].id);
      }
      return throwError(() => err);
    })
  );
};
